import { createRouter, createWebHistory, createMemoryHistory } from "vue-router";

import GenerateTokenPage from "./components/pages/GenerateTokenPage";
import VerifyTokenPage from "./components/pages/VerifyTokenPage";

const routes = [
  {
    path: "/",
    name: "generateToken",
    component: GenerateTokenPage,
  },
  {
    path: "/verify_token",
    name: "verifyToken",
    component: VerifyTokenPage,
  },
];

const history = process.env.NODE_ENV === 'production' ? createMemoryHistory() : createWebHistory();

const router = createRouter({
  history,
  routes,
});

export default router;
