import Vuex from 'vuex';

const storeFile = new Vuex.Store({
  state: {
    fileData: '',
  },
  mutations: {
    SET_FILE: (state, newValue) => {
      state.fileData = newValue
    },
  },
  actions: {
    setFile: ({commit, state}, newValue) => {
      commit('SET_FILE', newValue)
      return state.fileData
    },
  },
  getters: {
    getFile(state) {
      return state.fileData;
    },
  },
});

export default storeFile;
