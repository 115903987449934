<template>
    <nav class="navbar navbar-expand-lg fixed-top navbar-custom navbar-light sticky nav-sticky">
        <div class="container">
            <a class="navbar-brand" href="https://peeringhub.io/">
                <!-- Approze -->
                <img src="./../../assets/images/logo.png" class="l-dark" height="30" alt="">
            </a>
            <button class="navbar-toggler" @click="showToggle()" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="mdi mdi-menu"></span>
            </button>
            <!--end button-->

            <div :class="[showToggleValue == true ? 'collapse navbar-collapse show' : 'collapse navbar-collapse']" id="navbarCollapse">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="https://peeringhub.io/">Home</a>
                    </li>
                    <!--end nav item-->
                    <li class="nav-item">
                        <a class="nav-link" href="https://peeringhub.io/stir-shaken.html">STIR/SHAKEN</a>
                    </li>
                    <!--end nav item-->
                    <li class="nav-item">
                        <a class="nav-link" href="http://doc.peeringhub.io" target="_blank">Guide</a>
                    </li>
                    <!--end nav item-->
                    <li class="nav-item">
                        <a class="nav-link" href="https://peeringhub.io/stir-shaken.html#price">Price</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://peeringhub.io/#blog">Support</a>
                    </li>
                    <!--end nav item-->
                    <li class="nav-item">
                        <a class="nav-link" href="https://peeringhub.io/blog.html">Blog</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://peeringhub.io/index.html#contact">Contact</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="https://tool.peeringhub.io/">Try now</a>
                    </li>
                    <!--end nav item-->
                </ul>
                <!--end navbar nav-->
            </div>
            <!--end collapse-->
        </div>
        <!--end container-->
    </nav>
</template>

<script>
    export default {
        data() {
        return {
                showToggleValue: false,
            }
        },
        methods: {
            showToggle() {
                this.showToggleValue = !this.showToggleValue;
            },
        }
    };
</script>

<style>
    @import './../assets/styles/layouts/header.css';
</style>
