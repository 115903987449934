<template>
    <footer class="footer py-5 bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12 mt-4 pt-2">
                    <a href="https://peeringhub.io/">
                        <img src="./../../assets/images/logo-white.png" height="30" alt="">
                    </a>
                    <p class="text-foot mt-4">Peeringhub is focused on providing cutting-edge Stir/Shaken CA
                        technologies for service provider to protect their network from unauthorized calls. </p>
                    <ul class="list-unstyled social-icon social mb-0">
                        <li class="list-inline-item">
                            <a href="https://www.facebook.com/Peeringhub-102756598508748">
                                <i class="mdi mdi-facebook" title="facebook"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://www.linkedin.com/company/peeringhub">
                                <i class="mdi mdi-linkedin" title="linkedin"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <!--end col-->

                <div class="col-lg-2 col-md-4 mt-4 pt-2">
                    <h4 class="text-light text-uppercase footer-head">Links</h4>
                    <ul class="list-unstyled footer-list mt-4">
                        <li><a href="https://peeringhub.io/privacy.html" class="text-foot">
                            <i class="mdi mdi-chevron-right mr-2"></i>Privacy Policy</a>
                        </li>
                        <li><a href="https://peeringhub.io/subscription.html" class="text-foot">
                            <i class="mdi mdi-chevron-right mr-2"></i> Subscription</a>
                        </li>
                    </ul>
                </div>
                <!--end col-->

                <div class="col-lg-3 col-md-6 mt-4 pt-2">
                    <h4 class="text-light text-uppercase footer-head">Supports</h4>
                    <ul class="list-unstyled footer-list mt-4">
                        <li><a href="https://peeringhub.io/#contact" class="text-foot"><i class="mdi mdi-chevron-right mr-2"></i> Chat with
                            Us</a>
                        </li>
                        <li><a href="https://support.peeringhub.io/servicedesk/customer/portals?customize=true" class="text-foot"><i class="mdi mdi-chevron-right mr-2"></i> Submit a Ticket</a>
                        </li>
                    </ul>
                </div>
                <!--end col-->

                <div class="col-lg-3 col-md-4 mt-4 pt-2">
                    <h4 class="text-light text-uppercase footer-head">Location</h4>
                    <ul class="list-unstyled text-foot mt-4 mb-0">

                        <li> 3524 Silverside Road Suite 35B, Wilmington, Delaware, 19810<br></li>

                        <li> &#x202A;(484) 424-9683&#x202C;<br></li>
                    </ul>

                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </footer>
    <footer class="footer footer-bar py-4 bg-dark text-center">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <p class="mb-0 text-foot">@ <span class="current-year">2023</span> Peeringhub Inc.</p>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </footer>
</template>
<script>
    export default {};
</script>
<style>
    @import './../assets/styles/layouts/footer.css';
</style>
