<template>
    <div id="app">
        <Header />
        <router-view />
        <Footer />
    </div>
</template>

<script>
    import Header from "@/components/layouts/Header.vue";
    import Footer from "@/components/layouts/Footer.vue";

    export default {
        name: "App",
        components: {
            Header,
            Footer,
        },
    };
</script>

<style>
    @import '@/assets/styles/libraries/normalize.css';
    @import '@/assets/styles/libraries/matherialDesginIcons.css';
    @import '@/assets/styles/fonts.css';

    @import '@/assets/styles/icons.css';
    @import '@/assets/styles/drag.css';
    @import '@/assets/styles/inputs.css';
    @import '@/assets/styles/spaces.css';
    @import '@/assets/styles/title.css';
    @import '@/assets/styles/buttons.css';
    @import '@/assets/styles/general.css';
</style>
