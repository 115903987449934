<template>
    <div class="container container--special form mt-90 mb-50">
        <div class="text-center">
            <div class="undertitle">Try it Out</div>
            <h2 class="mb-50">Generate and Verify your Token</h2>
            <nav>
                <router-link class="navbarButton navbarButtonActive" to="/">
                    <img :src="iconGenerateToken" class="icon-custom" /> 
                    Generate Token
                </router-link>
                
                <router-link class="navbarButton" to="/verify_token">
                    <img :src="iconVerifyToken" class="navbarImgDeActivate icon-custom" /> 
                    Verify Token
                </router-link>
            </nav>
        </div>

        <form class="mb-30" @submit.prevent="generateTokenSubmit">
            <div class="form-group">
                <label>Upload Private Key</label>
                <div>
                    <div v-if="!file">
                        <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
                            <div class="dropZone-info" @drag="onChange">
                                <span class="fa fa-cloud-upload dropZone-title"></span>
                                <span class="dropZone-title">Drag and drop file here or</span>
                                <div class="dropZone-upload-limit-info">
                                    <div>
                                        <span class="text-underline">browse</span> file
                                    </div>
                                </div>
                            </div>
                            <form id="uploadForm" enctype="multipart/form-data" v-on:change="onChange">
                                <input type="file">
                            </form>
                        </div>
                    </div>
                    <div v-else class="dropZone-uploaded">
                        <div class="dropZone-uploaded-info">
                            <span class="dropZone-title">{{ file.name }}</span>
                            <button type="button" class="btn btn-danger removeFile" @click="removeFile">Remove File</button>
                        </div>
                    </div>
                    
                    <div class="uploadedFile-info">
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label>Private Key Password</label>
                <input
                    type="password"
                    placeholder="••••••••••••••••••"
                    v-model="private_key_phase"
                    class="form-control"
                />
            </div>

            <div class="form-group">
                <label>Certificate URL</label>
                <input
                    type="text"
                    placeholder="Enter URL"
                    v-model="cert_url"
                    class="form-control"
                />
            </div>

            <div class="form-group">
                <label>Caller ID</label>
                <input
                    type="text"
                    placeholder="Enter ID"
                    v-model="ani"
                    class="form-control"
                />
            </div>

            <div class="form-group">
                <label>Calling Number</label>
                <input
                    type="text"
                    placeholder="Enter Number"
                    v-model="dnis"
                    class="form-control"
                />
            </div>

            <div class="form-group text-center mt-35">
                <button type="submit" class="submitBtn">Generate Token</button>
            </div>
        </form>

        <div v-if="this.resultToken">
            <div class="form-group">
                <label>The generated token result</label>
                <textarea
                    placeholder=""
                    cols="80"
                    rows="10"
                    v-model="resultToken"
                    class="form-control"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { API_LINK, GENERATE_TOKEN } from '../../configuration/api';
    import axios from "axios";

    export default {
        components: {
       
        },
        data() {
            return {
                private_key_file: '',
                private_key_phase: '',
                cert_url: '',
                ani: '',
                dnis: '',

                file: '',
                dragging: false,

                resultToken: '',

                iconGenerateToken: require("@/assets/images/pages/bx-add-to-queue.svg"),
                iconVerifyToken: require("@/assets/images/pages/bx-shield-dark.svg"),
            };
        },
        methods: {
            updateFile (value) {
                this.$store.commit('SET_FILE', value);
            },
            onChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    this.dragging = false;
                    return;
                }
                this.createFile(files[0]);
            },
            createFile(file) {
                if (file.size > 5000000) {
                    alert('Please check file size no over 5 MB.')
                    this.dragging = false;
                    return;
                }    
                this.file = file;
                // this.updateFile(file);
            },
            removeFile() {
                this.file = '';
                this.updateFile('');
            },
            generateTokenSubmit() {
                let config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'   
                        }
                    }
                let formData = new FormData();
                
                formData.append('cert_url', this.cert_url);
                formData.append('ani', parseInt(this.ani));
                formData.append('dnis', parseInt(this.dnis));
                formData.append('private_key_phase', this.private_key_phase);
                formData.append('private_key_file', this.file);
                
                axios
                    .post(`${API_LINK}${GENERATE_TOKEN}`, formData, config)
                    .then((response) => {
                        this.resultToken = JSON.stringify(response.data, null, 4);
                    })
                    .catch((error) => {
                        alert(error, error.response);
                    });
            },
        },
        computed: {
            getFile () {
                return this.$store.getters.getFile;
            },
            extension() {
                return (this.file) ? this.file.name.split('.').pop() : '';
            },
        },
    };
</script>

<style>
    @import '../../assets/styles/components/dropZone.css';
</style>
