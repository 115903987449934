import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/styles/libraries/bootstrap.css";
import ResizeTextarea from 'resize-textarea-vue3'
import router from "./routers";
import store from "./store/file";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ResizeTextarea);
app.mount("#app");
