<template>
    <div class="container container--special form mt-90 mb-50">
        <div class="text-center">
            <div class="undertitle">Try it Out</div>
            <h2 class="mb-50">Generate and Verify your Token</h2>
            <nav>
                <router-link class="navbarButton" to="/">
                    <img :src="iconGenerateToken" class="navbarImgDeActivate icon-custom" />
                    Generate Token
                </router-link>

                <router-link class="navbarButton navbarButtonActive" to="/verify_token">
                    <img :src="iconVerifyToken" class="icon-custom" />
                    Verify Token
                </router-link>
            </nav>
            <div class="mt-45">
                <p class="paragraphTitle">Verify identity token</p>
            </div>
        </div>

        <form class="mb-30">
            <div>
                <div class="form-group">
                    <div class="flex space-between">
                        <div>
                            <label class="mt-15">Enter token</label>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="form-group mb-20">
                        <textarea
                            placeholder="Enter your token to verify"
                            cols="80"
                            rows="8"
                            v-model="token"
                            class="form-control"
                        ></textarea>
                    </div>

                    <div class="form-group text-center">
                        <button type="button" class="submitBtn" v-on:click="verifyTokenSubmit">Verify Token</button>
                    </div>
                </div>
            </div>

            <div class="form-group mt-20">
                <div class="flex space-between" v-if="this.cert">
                    <div>
                        <label>Token</label>
                    </div>
                    <div>
                        <div class="flex">
                            <div v-bind:class="{ 'gray-color': !clicked, '': clicked }">Boxes &nbsp;</div>
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input custom-control-input"
                                    v-on:click="clickSwitcher()"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked
                                />
                            </div>
                            <div v-bind:class="{ '': !clicked, 'gray-color': clicked }">
                                <div class="ml-2i5em">Full Token</div>
                            </div>
                        </div>
                    </div>
                </div>
                <resize-textarea
                    v-if="this.cert"
                    v-show="!clicked"
                    :autoResize="true"
                    :cols="80"
                    v-model="dataResponse0"
                    class="form-control textArea textArea--grayBackground"
                >
                </resize-textarea>
            </div>

            <div v-show="clicked">
                <div class="form-group" v-if="this.data1">
                    <label>Header:</label>
                    <resize-textarea
                        :autoResize="true"
                        :cols="80"
                        v-model="data1"
                        class="form-control textArea textArea--grayBackground"
                    >
                    </resize-textarea>
                </div>

                <div class="form-group" v-if="this.data2">
                    <label>Payload:</label>
                    <resize-textarea
                        :autoResize="true"
                        :cols="80"
                        v-model="data2"
                        class="form-control textArea textArea--grayBackground"
                    >
                    </resize-textarea>
                </div>

                <div class="form-group" v-if="this.data3">
                    <label>Verify signature:</label>
                    <resize-textarea
                        :autoResize="true"
                        :cols="80"
                        v-model="data3"
                        class="form-control textArea textArea--grayBackground"
                    >
                    </resize-textarea>
                </div>

                <div class="form-group mb-50" v-if="this.data4">
                    <label>Certificate:</label>
                    <resize-textarea
                        :autoResize="true"
                        :cols="80"
                        v-model="data4"
                        class="form-control textArea textArea--grayBackground"
                    >
                    </resize-textarea>
                </div>

                <div class="form-group text-center" v-if="this.data1">
                    <button type="button" class="submitBtn ghostBtnModificator" v-on:click="decryptCertificateSubmit">
                        Initial view of certificate
                    </button>
                </div>
            </div>
        </form>

        <div v-show="!clicked">
            <div class="form-group" v-if="this.decrypt">
                <label>Certificate:</label>
                <div>
                    <resize-textarea
                        :autoResize="true"
                        :cols="80"
                        v-model="decrypt"
                        class="form-control textArea textArea--grayBackground"
                    >
                    </resize-textarea>
                </div>
            </div>

            <!-- <div class="form-group text-center mt-35" v-if="this.cert">
            <button type="button" class="submitBtn ghostBtnModificator" v-on:click="decryptCertificateSubmit">
                Decrypt Certificate
            </button>
        </div> -->
        </div>
    </div>
</template>

<script>
import { API_LINK, DECRYPT_CERTIFICATE, VERIFY_TOKEN } from '../../configuration/api'

import axios from 'axios'

export default {
    data() {
        return {
            clicked: false,

            token: '',
            cert: '',
            decrypt: '',
            data0: '',
            data1: '',
            data2: '',
            data3: '',
            data4: '',

            dataResponse0: '',
            dataResponse1: '',
            dataResponse2: '',
            dataResponse3: '',
            dataResponse4: '',

            iconGenerateToken: require('@/assets/images/pages/bx-add-to-queue-dark.svg'),
            iconVerifyToken: require('@/assets/images/pages/bx-shield.svg'),

            dataLink: '',
        }
    },
    el: 'textarea',
    methods: {
        clickSwitcher() {
            this.clicked = !this.clicked
            if (this.clicked === true) {
                this.data0 = this.dataResponse0
                this.data1 = this.dataResponse1
                this.data2 = this.dataResponse2
                this.data3 = this.dataResponse3
                this.data4 = this.dataResponse4
            }
        },
        decryptCertificateSubmit() {
            axios
                .post(`${API_LINK}${DECRYPT_CERTIFICATE}?url=${this.dataResponse3}`, {
                    headers: {
                        'Content-Type': 'multipart/form-data;',
                    },
                })
                .then((data) => {
                    this.cert = ''

                    this.decrypt = data.data.cert
                    this.cert = this.decrypt
                })
                .catch((error) => {
                    alert(error, error.response)
                })
        },
        verifyTokenSubmit() {
            this.cert = ''
            this.data0 = ''
            this.data1 = ''
            this.data2 = ''
            this.data3 = ''
            this.data4 = ''
            this.decrypt = ''
            axios
                .post(`${API_LINK}${VERIFY_TOKEN}`, {
                    token: this.token,
                })
                .then((response) => {
                    this.cert = response.data.token.substring(
                        response.data.token.indexOf('-----BEGIN CERTIFICATE-----') + 1
                    )
                    this.dataResponse0 = response.data.token.replace(this.cert, '')

                    this.dataResponse4 = response.data.token.substring(
                        response.data.token.indexOf('-----BEGIN CERTIFICATE-----') + 1
                    )

                    // TO DO: Refactor wrong receive from API format string (Need change API)
                    let dataResponse = response.data.token
                        .replaceAll('\n', '')
                        .replace('}.{', '}, Info: {')
                        .replace('Downloading certificate...', ', Cert: {Downloading certificate...')
                        .replace('-----END CERTIFICATE-----', '-----END CERTIFICATE-----}')

                    dataResponse = dataResponse.replace(
                        'Downloading certificate...Validating signature...Certificate:',
                        ''
                    )
                    dataResponse = '{' + dataResponse + '}'
                    dataResponse = dataResponse
                        .replaceAll(';', ',')
                        .replace('-----END CERTIFICATE-----', '-----END CERTIFICATE-----"')
                        .replace('Serial: ', 'Serial: "')
                        .replace('Subject CN:', 'Subject CN:"')
                        .replace(',    Issuer:', '",    Issuer:"')
                        .replace(',    Serial', '",    Serial')
                    dataResponse = dataResponse
                        .replace('Identity:', '"Identity":')
                        .replace('Cert:', '"Cert":')
                        .replace('Info:', '"Info":')
                    dataResponse = dataResponse
                        .replace('Subject CN', '"Subject CN"')
                        .replace('Issuer', '"Issuer"')
                        .replace('Serial', '"Serial"')
                    dataResponse = JSON.parse(dataResponse)

                    this.dataResponse1 = JSON.stringify(dataResponse.Identity, null, 4)
                    this.dataResponse2 = JSON.stringify(dataResponse.Info, null, 4)
                    // this.dataResponse3 = JSON.stringify(dataResponse.Cert, null, 4);
                    this.dataResponse3 = dataResponse.Identity.x5u
                    this.decryptCertificateSubmit()
                })
                .catch((error) => {
                    alert(error, error.response)
                })
        },
    },
    updated() {},
    mounted() {},
}
</script>

<style></style>
